
document. addEventListener( 'DOMContentLoaded', function () {
    iniciarApp();
    eventListerner();
    // mostrarParra();
});

function iniciarApp () {
    scrollNav();

}

function scrollNav() {
    const enlaces = document.querySelectorAll('.navegacion-principal a');
    
    enlaces.forEach ( enlace => {
        enlace.addEventListener('click', function(e){
            e.preventDefault();

            const seccionScroll = e.target.attributes.href.value;
            const seccion = document.querySelector(seccionScroll);
            seccion.scrollIntoView({ behavior: "smooth"});  // efecto scroll al llevar al hipervinculo

        });

    });
}

function eventListerner() {
    const mobileMenu = document.querySelector('.mobile-menu');

    mobileMenu.addEventListener('click', navegacionResponsive);
}



function navegacionResponsive(){
    const navegacion = document.querySelector('.navegacion-principal');
    navegacion.classList.toggle('mostrar');
}

// function eventListernerparra() {
//     const BeneficioParra = document.querySelector('.BeneficioParra');
//     BeneficioParra.addEventListener('click', mostrarParra);
// }

// function mostrarParra() {
//     const mostraparra = document.querySelector('.BeneficioTit');
//     mostraparra.classLis.ttoggle('mostraParra')
// }